/* *{
    margin:0px;
    padding:0px;
    box-sizing: border-box;
} */

nav{
    display: flex !important;
    justify-content: space-around;
    align-items: center;
    min-height: 5vh;
}

nav .header{
    color:#fdcc52;
    letter-spacing: 2px;
    font-size: 20px;
    cursor: pointer !important;
}

nav .header:hover{
    color:white;
}

nav ul{
    display: flex !important;
    justify-content: space-around;
    width: 30%;
    margin: 0px;
}
nav ul li {
    list-style: none;
}

nav ul a{
    color: rgba(255, 255, 255, 0.692);
    text-decoration: none;
    letter-spacing: 3px;
    font-size: 10px;

}
nav ul a:hover{
    color: rgba(255, 255, 255);
}

.main-nav{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.h-100 {
    height: 100% !important;
}
.vh-100 {
    top:1;
    height: 100vh !important;
}
.container{
    margin: auto;
    width: 100%;
}
.motiv{
    display: flex !important;
    align-content: center;
    justify-content: space-around ;
}
.motiv-col{
    margin: auto !important;
}

.intro{
    position: relative;
    padding: 150px 0
}
.intro-cont{
    text-align: center !important;
}
.chart{
    position: relative;
    padding: 100px 0
}

hr{
    max-width: 100px;
    margin: 25px auto 0;
    border-width: 1px;
    border-color: rgba(34, 34, 34, 0.1);
}

.traits{
    position: relative;
    padding: 100px 0
}

.return-to-top{
    position: fixed;
    bottom: 20px;
    right: 20px;
    /* background: rgb(0, 0, 0); */
    background: rgba(0, 0, 0, 0.7);
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-decoration: none;
    border-radius: 35px;
    transition: all 0.3s ease;
    padding: auto;
    color: rgba(255, 255, 255, 0.829);
}

.return-to-top:hover{
    color: white;
}

footer{
    padding: 25px 0;
    text-align: center;
    color: rgba(255, 255, 255, 0.3);
    background-color: #222222;

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .container {
        max-width: 540px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {.container {
    max-width: 720px;
}}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}